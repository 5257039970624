import React from 'react';
import './About.css';
import aboutmission from './theme/images/about/about-mission.jpg'
import familia from './theme/images/about/familia.jpeg'
import {  BrowserRouter as Router } from 'react-router-dom';

const About = () => (
  <>
  <Router>
    <section className="page-title bg-about">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block text-center">
              <h1 className="text-capitalize mb-4 text-lg">Sobre Nós</h1>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                <li className="list-inline-item"><span className="text-white">/</span></li>
                <li className="list-inline-item text-white-50">Sobre</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section about-2 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-item pr-3 mb-5 mb-lg-0 text-about">
              <h2 className="mt-3 mb-4 position-relative content-title">Transformamos sua viagem dos sonhos em experiências reais</h2>
              <p className="mb-5">Somos uma nova agência de viagens cujo maior propósito é proporcionar a melhor experiência de viagem para você.
              <br />
              Nós ajudamos você a tirar seus sonhos do papel, oferendo atendimento único e individualizado para adequar a todas as suas necessidades e com o melhor custo-benefício.
              <br />
              <u className="text-color"><b className="text-color">Solicite já uma cotação e entre para o nosso time de viajantes!</b></u>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-item-img">
              <img loading="lazy" src={aboutmission} alt="about-image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>

  
    <section className="about-info section pt-0 text-about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3"><span className="text-color mr-2 text-md">01. </span>Missão</h3>
              <p>Cuidar, planejar e organizar sua viagem oferencendo o melhor custo-benefício, segurança e conforto, gerando para você uma experiência única.
                <br /> 
                <b className="text-color">Aqui seus sonhos viram realidade!</b>
              </p>
            </div>		
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3"><span className="text-color mr-2 text-md">02. </span>Visão</h3>
              <p>Mostrar que viajar é acessível a todos e ajudar o maior número de pessoas a conquistar o sonho de conhecer cada cantinho do mundo.</p>
            </div>		
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3"><span className="text-color mr-2 text-md">03. </span>Valores</h3>
              <p>
                Ética;
                <br />
                Transparência;
                <br />
                Prestação de serviço de qualidade.
              </p>
            </div>		
          </div>
        </div>
      </div>
    </section>
    <section className="section about-2 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-item pr-3 mb-5 mb-lg-0 text-about">
              <h2 className="mt-3 mb-4 position-relative text-color">Um pouco mais sobre nós...</h2>
              <p className="mb-5">
                A Pepper nasceu com o objetivo de ajudar pessoas a conhecer e explorar novos destinos, criar memórias e ter experiências incríveis. Por muito tempo, eu tive a impressão de que viajar era somente pra quem tivesse muito dinheiro e/ou tempo sobrando, e nunca achei que eu seria capaz de viajar e conhecer outros lugares, justamente por esse motivo.
                <p />
                Quando finalmente fiz minha primeira viagem, criei o roteiro, conheci e explorei todos os cantinhos que pude, foi que virou uma chave na minha cabeça e toda a oportunidade que tenho eu gosto de conhecer um lugar novo, fazer amigos inesperados, comer comidas típicas. 
                <br/>
                <i><b>Nem sempre é preciso pegar um avião pra isso, mas confesso que tenho uma paixão por voar e a sensação da decolagem que eu amo rs</b></i>
                <p />
                Como mãe solo e visando uma nova oportunidade de empreender, meu lado viajante uniu o útil ao agradável e iniciamos o projeto de uma nova agência de viagens, com o foco no cliente, qualidade de serviço e um atendimento mais humanizado e empático.
                <p />
                Hoje o que eu quero proporcionar para os meus clientes é essa experiência, essa virada de chave, onde você pode curtir um novo destino, explorando o seu lado viajante e descobrindo o que você mais gosta de explorar: culinária, museus, festas, trilhas, pontos turísticos, se gosta de conhecer novas pessoas e se conectar. Aqui nós fazemos de acordo com o seu gosto.
                <p />
                <span className="text-color">Eu convido você a se permitir também!</span>
               </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-item-img">
              <img loading="lazy" src={familia} alt={familia} className="img-fluid-familia img-fluid img-thumbnail" />
            </div>
          </div>
        </div>
      </div>
    </section>



  </Router> 
  </>
    )

export default About;