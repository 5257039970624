import React from 'react';
import pepperdestinosfluxo from './theme/images/contact/pepperdestinosfluxo.png'
import {  BrowserRouter as Router } from 'react-router-dom';
import './Service.css';
import { FaPlaneDeparture, FaCarSide, FaParachuteBox} from 'react-icons/fa';
import { RiHotelFill } from 'react-icons/ri';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const Service = (props) => (
  <>
  <Router>
    <section className="page-title bg-services">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block text-center">
              <h1 className="text-capitalize mb-4 text-lg">Serviços</h1>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                <li className="list-inline-item"><span className="text-white">/</span></li>
                <li className="list-inline-item text-white-50">Serviços</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
      <div className="container">
        <div className="row text-center">
            <h2 className="mt-3 mb-4 position-relative content-title-services">
              Hmmm... <span>Pepper</span>, me explica como funciona?
            </h2>
            <p className="text-services">
              Nosso trabalho vai além de encontrar para você o melhor preço de passagens aéreas, nós faremos a assistência integral da sua viagem. 
             <br />
              Olha como funciona: 
            </p>
          <img loading="lazy" src={pepperdestinosfluxo} alt={pepperdestinosfluxo} className="img-fluid img-services" />
        </div>
        <div className="row text-center">
            <h2 className="mt-3 mb-4 position-relative content-title-services">
            Legal! E quais são os serviços que vocês oferecem?
            </h2>
            <p className="text-services text-start text-margin">

              <FaPlaneDeparture className="icon-services"/> Passagens aéreas nacionais e <br />internacionais
              <br /><RiHotelFill className="icon-services"/> Hospedagens 
              <br /><FaCarSide className="icon-services"/> Transfers e/ou aluguel de carros
              <br /><FaParachuteBox className="icon-services"/> Passeios 
            </p>
        </div>
       
        <div className="row text-center">
            <h2 className="mt-3 mb-4 position-relative content-title-services">
            Vocês aceitam todos as formas de pagamento?
            </h2>
            <p className="text-services text-margin">
              <b>Atualmente aceitamos as seguintes formas de pagamento:</b>
              <br />
              <ul>
                <span><AiOutlineCheckCircle /></span> Pix
                <br />
                <span><AiOutlineCheckCircle /></span> Transferência bancária
                <br />
                <span><AiOutlineCheckCircle /></span> Cartão de crédito à vista ou <br /> parcelado (sujeito à taxas)
              </ul>
            </p>
            <br />
        </div>

        <div className="row text-center">
            <h2 className="mt-3 mb-4 position-relative content-title-services">
            <span>Pepper</span>, e como garantir tudo isso? É seguro mesmo?
            </h2>
            <p className="text-services">
              Para que haja segurança e transparência de todos os serviços prestados, o cliente receberá um contrato para que haja um respaldo para ambas as partes.
              Esse contrato será enviado, após o cliente aceitar a proposta de cotação e formalizarmos a forma de pagamento.
              <br />
              Para pagamentos realizados com cartão de crédito, há uma segurança adicional fornecida pela própria operadora: onde caso o serviço não seja prestado
              ou a compra reconhecida, basta que seja feita a contestação dessa compra, e caso seja confirmado, o valor é estornado integralmente.
            </p>
            <br />
        </div>

        <div className="row text-center">
            <h2 className="mt-3 mb-4 position-relative content-title-services">
            <span>Outras informações importantes...</span>
            </h2>
            <p className="text-services align-self-center">
            <li>
                Nossas passagens são emitidas com milhas aéreas e por isso conseguimos gerar cotações com preços competitivos.
              </li>
              <br />              
              <li>
                Para sua segurança e a nossa, ao aceitar e fechar uma cotação você concorda que os dados pessoais solicitados os dados pessoais e dados comprobatórios serão com o fim único e exclusivo de assegurar que as emissões sejam feitas corretamente
                e que não haja problemas com a transação.
                <br />
                Ao fim da cotação, caso você opte por não fechar conosco, você poderá enviar a qualquer momento um e-mail solicitando a exclusão das informações para <span>contato@pepperdestinos.com</span>.
              </li>
              <br />
              <li>
                No caso de utilizar suas informações pessoais para outros fins, por exemplo: comerciais e para marketing de divulgação, solicitaremos o seu consentimento antes e só será feito com sua expressa autorização.
              
              </li>
              <br />
              <li>Se você tem alguma dúvida ou sugestão, entra em contato conosco através do whatsapp, e-mail ou instagram e ficaremos mais que felizes em respondê-lo(a) e também é possível agendar uma vídeo chamada para um bate-papo.</li>
            </p>
        </div>
      </div>
    
  </Router>
  </>
    )

export default Service;