import firebase from "./firebase";

class StoreData {

    createConfiguration(configuration) {
        return new Promise(async (resolve, reject) => {
            await firebase.firestore()
            .collection("contact")
            .add(configuration)
            .then(resolve)
            .catch(reject)
        })
    }
}

export default new StoreData();