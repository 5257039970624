import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import {ImWhatsapp} from 'react-icons/im';
import './Header.css';
import { BrowserRouter as Router } from 'react-router-dom';
import logo from './theme/images/logo_pepperdestinos.png'

const Header = (props) => (
  <>
  <Router>
    <Navbar expand="lg"  id="navbar" className="ml-auto px-0 py-4 fixed-top" variant="light"  >
      <Container>
        
        <Navbar.Brand href="/" className="font-logo">
         {/* <h3>Pepper<span>Destinos</span></h3> */}
         <img className="logo" src={logo} alt={logo}/>
        </Navbar.Brand>
        
      <Navbar.Toggle aria-controls="navbarScroll" className="navbar"/>        
      <Navbar.Collapse id="navbarScroll" className="row justify-content-end">
          <Nav  className="ml-auto text-center justify-content-end" >
            <Nav.Item as="li" className="nav-item active">
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>          
            <Nav.Link href="/about">Sobre Nós</Nav.Link>
            <Nav.Link href="/service">Serviços</Nav.Link>
            {/* <Nav.Link href="/clients">Clientes</Nav.Link> */}
            <a className="nav-link" href={props.instagram} aria-label="instagram"><FaInstagram /></a>
            <a className="nav-link" href={props.facebook} aria-label="instagram"><FaFacebookF /></a>
            <a className="nav-link" href={props.whatsapp} aria-label="whatsapp"><ImWhatsapp /></a>
            <Nav.Link href="/contact" className="btn btn-solid-border btn-round-full">Solicitar Cotação</Nav.Link>
          </Nav>
        </Navbar.Collapse>        
      </Container>
    </Navbar>
  </Router>
  </>
)

export default Header;