import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import './Home.css';
import TouristSpot from './TouristSpot';
import InstagramEmbed from 'react-instagram-embed';
import banner from './theme/images/home/home-banner.jpeg'
import homebgmap from './theme/images/home/bg-home-mapa.jpeg'
import CompanyCarousel from './CompanyCarousel';
import { BsArrowRight } from 'react-icons/bs'


const Home = () => (
  <>
  <Container fluid className="margin animation">
    <Row className ="align-items-center">
      <Col>
        <TouristSpot />
      </Col>
      <Col className="text-center">
      <img className="img-fluid img-props2" loading="lazy" src={homebgmap} alt={homebgmap} />
      <Nav.Link href="/contact" className="col text-center btn btn-home-solid-border btn-home-round-full">Quero fazer uma Cotação <BsArrowRight /> </Nav.Link>
      </Col>
    </Row>
  </Container>  
  <CompanyCarousel />
    
  <Container fluid className="home-banner">
    <img className="home-banner-img" src={banner} alt={banner} align="left"/>
    <p className="home-banner-title"> Menos rotina, mais roteiros </p>
    <p className="home-banner-subtitle">
      Crie novas memórias, colecione experiências, faça novos amigos, esteja perto de quem você ama.
    </p> 

  </Container>
  {/* <Container>
    <h1 className="text-center text-promo">Acompanhe nossas promoções</h1>

    <InstagramEmbed
      url='https://www.instagram.com/pepperdestinos/'
      clientAccessToken='205010528409069|IGQVJYOWl2dFlycnpWVWw0SXdrOGY1WFJhaG1memxxU2NqVGVDV0I3WmhEMGhmTGRhZA3ZABcGhqTEc4elNiTzd3OE1oREpuYWg3VDZAfTmk0WTRoMTVRRTkxWEg0ckpKVmlEQktqWnV6QVZATUml6N3FkTXVkWVotMlR6MlM0'
      maxWidth={500}
      hideCaption={true}
      containerTagName='div'
      injectScript
    />
  </Container> */}
  
  </>
);

export default Home;