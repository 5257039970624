import Header from './Header';
import Footer from './Footer';
import React from 'react';
import 'react-floating-whatsapp/dist/index.css';
import { Switch, BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import About from './About';
import Service from './Service';
import Contact from './Contact';
import Clients from './Clients';
import Home from './Home';
import NotFound from './NotFound';


const phoneNumber = '+55 21 98158-5612'
const instagram = 'https://www.instagram.com/pepperdestinos'
const whatsapp ='https://wa.me/message/K3JFPVCEPTXFE1'
const email = 'contato@pepperdestinos.com'
const facebook = 'https://www.facebook.com/Pepper-Destinos-106409035162310'

function App() {
  return (
  <>
    <Router>
      <Header instagram={instagram} whatsapp={whatsapp} facebook={facebook} />  
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/service">
          <Service />
        </Route>
        <Route path="/contact">
          <Contact phoneNumber={phoneNumber} />
        </Route>
        <Route path="/clients">
          <Clients />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route component={NotFound} />
      </Switch>
      
      <Footer instagram={instagram} whatsapp={whatsapp} email={email} phoneNumber={phoneNumber} facebook={facebook}/>     
    </Router>
  </>
  
  )
  
}

export default App;
