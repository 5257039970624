import firebase from 'firebase/compat/app';
import "firebase/compat/analytics"
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUMENT_ID
  };

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

async function activateAnalytics() {
    let isSupported = await firebase.analytics.isSupported();
    if (isSupported) firebase.analytics();
}

activateAnalytics()

export default firebase
