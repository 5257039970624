import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import paris from './theme/images/home/carousel/paris.jpg'
import newyork from './theme/images/home/carousel/newyork.jpg'
import dunas from './theme/images/home/carousel/dunas.jpg'
import chamonix from './theme/images/home/carousel/chamonix.jpg'
import coupleonthebeach from './theme/images/home/carousel/coupleonthebeach.jpg'


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  

const TouristSpot = () => (
    <Carousel 
        responsive={responsive}
        swipeable={true}
        draggable={false}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
        containerClass="container-props"
  >
      
      <div>
        <img className="img-fluid img-props" src={coupleonthebeach} alt={coupleonthebeach}/>
      </div>

      <div>
        <img  className="img-fluid img-props" src={paris} alt={paris}/>
      </div>

      <div>
        <img className="img-fluid img-props" src={dunas} alt={dunas}/>
      </div>

      <div>
        <img className="img-fluid img-props" src={newyork} alt={newyork}/>
      </div>

      <div>
        <img className="img-fluid img-props" src={chamonix} alt={chamonix}/>
      </div>
    </Carousel>
)

export default TouristSpot;
