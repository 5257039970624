import React from 'react';
import { Formik, Field, Form } from "formik";
import './Contact.css';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Component } from 'react';
import CompanyCarousel from './CompanyCarousel';
import InputMask from 'react-input-mask';
import storeData from './Firebase/StoreData';

function sendForm(formData) {
  formData.isVerified = false;
  storeData.createConfiguration(formData)
    .then(() => {
      alert("Recebemos sua solicitação, em breve entraremos em contato! :)")
      
      },
      err => { alert("Houve um problema com o envio dos seus dados. Por enquanto, pode entrar em contato através do whatsapp?") })    
}
class Contact extends Component {
  render() {

    return (
      <>
        <section className="page-title bg-cotacao">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <h1 className="text-capitalize mb-4 text-lg">Cotação</h1>
                  <ul className="list-inline">
                    <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item"><span className="text-white">/</span></li>
                    <li className="list-inline-item text-white-50">Cotação</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div>
          <CompanyCarousel />
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Formik
                initialValues={{
                  "name": '',
                  "email": '',
                  "phoneNumber": '',
                  "flyingFrom": '',
                  "flyingTo": '',
                  "travelDetail": '',
                  "startDate": '',
                  "endDate":'',
                  "paxNumber": 'DEFAULT',
                  "paxKidNumber": 'DEFAULT'
                }}
                validationSchema={validationSchema}


                onSubmit={async (values, { resetForm }) => {
                  
                  await new Promise((r) => setTimeout(r, 500));
                  
                  sendForm(values);
                  resetForm()

                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,

                }) => (
                  
                  <Form className="form-control form-control-2 form-font">
                    <p />
                    <h5 className="text-align-center">Preencha o formulário abaixo ou fale conosco pelo whastapp {this.props.phoneNumber} </h5>
                    <p />
                      <div className="row form-group">
                        <Field
                          name="name"
                          id="144253760"
                          type="text"
                          placeholder="Nome completo *" />
                        {touched.name && errors.name && <div className="error-msg">{errors.name}</div>}

                      </div>
                      <div className="row">
                        <div className="form-group col col-xs-5">
                          <Field
                            name="email"
                            id="1276225596"
                            type="email"
                            placeholder="E-mail *"
                          />
                          {touched.email && errors.email ? <div className="error-msg">{errors.email}</div> : null}
                        </div>

                        <div className="form-group col">
                          <Field
                              name="phoneNumber"
                              id="1182933552"
                              render={({ field }) => (
                                <InputMask
                                  {...field}
                                  mask="(99) 99999-9999" 
                                  placeholder="Tel/Whatsapp (00) 00000-0000 *"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )}
                            />
                            {touched.phoneNumber && errors.phoneNumber && <div className="error-msg">{errors.phoneNumber}</div>}
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col">
                          <Field
                            name="flyingFrom"
                            id="269460547"
                            type="text"
                            placeholder="Origem *"
                          />
                          {touched.flyingFrom && errors.flyingFrom && <div className="error-msg">{errors.flyingFrom}</div>}
                        </div>
                        <div className="form-group col">
                          <Field
                            name="flyingTo"
                            id="661675540"
                            type="text"
                            placeholder="Destino *"
                          />
                          {touched.flyingTo && errors.flyingTo && <div className="error-msg">{errors.flyingTo}</div>}
                        </div></div>


                      <div className="row">
                        <div className="form-group col">
                          <Field
                            name="startDate"
                            id="1184113853">
                            {({ field, form: { setFieldValue } }) => {
                              return (
                                <DatePicker
                                  {...field}
                                  placeholderText="Data de Ida *"
                                  dateFormat='dd/MM/yyyy'
                                  minDate={new Date()}
                                  selected={field.value || null}
                                  onChange={(val) => {
                                    setFieldValue(field.name, val);
                                  }}
                                />
                              );
                            }}
                          </Field>
                          {touched.startDate && errors.startDate && <div className="error-msg">{errors.startDate}</div>}
                        </div>

                        <div className="form-group col">
                          <Field
                            name="endDate"
                            id="722148176">
                            {({ field, form: { setFieldValue } }) => {
                              return (
                                <DatePicker
                                  {...field}
                                  placeholderText="Data de Volta (opcional)"
                                  dateFormat='dd/MM/yyyy'
                                  minDate={values.startDate}
                                  selected={field.value || null}
                                  onChange={(val) => {
                                    setFieldValue(field.name, val);
                                  }}
                                />
                              );
                            }}
                          </Field>

                        </div></div>

                      <div className="row">
                        <div className="form-group col">
                          <select
                            name="paxNumber"
                            id="318434929"
                            type="select"
                            value={values.paxNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            defaultValue={'DEFAULT'}>
                            <option value="DEFAULT" disabled hidden label="Quantos adultos? *" />
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">7</option>
                            <option value="7">7</option>
                            <option value="8+">8+</option>
                          </select>
                          {touched.paxNumber && errors.paxNumber && <div className="error-msg">{errors.paxNumber}</div>}
                        </div>

                        <div className="form-group col">
                          <select
                            name="paxKidNumber"
                            id="2094214058"
                            value={values.paxKidNumber}
                            onChange={handleChange}
                            defaultValue={'DEFAULT'}>
                            <option value="DEFAULT" disabled hidden label="Quantas crianças?" />
                            <option value="0" label="Nenhuma" />
                            <option value="1" label="1" />
                            <option value="2" label="2" />
                            <option value="3" label="3" />
                            <option value="4" label="4" />
                            <option value="5+" label="5+" />
                          </select>
                        </div></div>

                      <div className="row form-group form-details">
                        <Field as="textarea"
                          name="travelDetail"
                          id="133660690"
                          placeholder="Conte-nos um pouco mais sobre sua viagem...(opcional)"
                          className="msg-box"
                        />
                      </div>
                      <div className="col text-center">
                        <button type="submit" className="btn btn-solid-border btn-round-contact">Enviar pedido</button>
                      </div>
                  </Form>
                )}

              </Formik>
              </div>
            </div>
          </div>
      </>
    )
  }
}

const validationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Por favor, nos informe seu nome'),
  email: Yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Por favor, informe um e-mail válido'),
  flyingFrom: Yup
    .string()
    .required('Por favor, nos informe a origem'),
  flyingTo: Yup
    .string()
    .required('Por favor, nos informe o destino'),
  paxNumber: Yup
    .string()
    .ensure()
    .required('Por favor, nos informe a quantidade de passageiros'),
  startDate: Yup
    .date()
    .required('Por favor, nos informe a data de ida'),
  phoneNumber: Yup
    .string()
    .required('Por favor, informe um telefone de contato')
    .test("MinLength", "Por favor, informe um telefone de contato", (val = "") => {
      const val_length_without_dashes = val.replace(/-|_/g, "").length;
      return val_length_without_dashes === 14;
    })

});

export default Contact;

