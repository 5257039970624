import React from 'react';
import { FaFacebook,  FaInstagram, FaTwitter } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ImWhatsapp} from 'react-icons/im';
import './Footer.css';
import parceiros from './theme/images/footer/parceiros-logo2.jpeg'
import { BrowserRouter as Router } from 'react-router-dom';
import FloatingWhatsApp from 'react-floating-whatsapp';
import './index.css';
import logo from './theme/images/logo_pepperdestinos.png';

const Footer = (props) => (
  <Router>
    <footer className="footer section">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="widget">
              <h5 className="text-capitalize mb-4">Empresa</h5>
              <ul className="list-unstyled footer-menu lh-35">
                <li><a href="/about">Sobre Nós</a></li>
                <li><a href="/service">Serviços</a></li>
                <li><a href="/clients">Clientes</a></li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="widget">
              <h5 className="text-capitalize mb-4">Redes Sociais</h5>
              <ul className="list-unstyled footer-menu lh-35">                
                <li><a href={props.instagram}><FaInstagram /> Instagram</a></li>
                <li><a href={props.facebook}><FaFacebook /> Facebook</a></li>
                <li><a href={props.whatsapp}><ImWhatsapp /> Whatsapp</a></li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="widget">
              <h5 className="text-capitalize mb-4 ">Parcerias</h5>
              <img className="partners" src={parceiros} alt={parceiros}/>
            </div>
          </div>
          <div className="col align-self-center">
            <div className="widget">
              <div className="logo mb-4 font-logo">
                <img className="logo" src={logo} alt={logo}/>
              </div>
              <p className="text-color text-sm">{props.email}</p>
              <p className="text-color text-sm">{props.phoneNumber}</p>
            </div>
          </div>
        </div>
          <div className="footer-btm pt-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-socials">
                &copy; 2021 Pepper Destinos<br />
                CNPJ 41.314.541/0001-71 
              </div>
            </div>
            <div className="col-lg-6 text-left text-lg-right">
              <ul className="list-inline footer-socials">
                Developed by<a href="https://github.com/iamaraalvarez" className="footer-socials">Iamara Alvarez</a>
                <li className="list-inline-item"><a href="https://www.facebook.com/yaah1"><FaFacebook /> Facebook</a></li>
                <li className="list-inline-item"><a href="https://twitter.com/alvareziamara"><FaTwitter /> Twitter</a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/iamara_alvarez/"><FaInstagram />Instagram</a></li>
                
              </ul>
            </div> 
          </div>
        </div>
      </div>

      <FloatingWhatsApp 
          phoneNumber={props.phoneNumber} 
          accountName='Pepper Destinos'  
          chatMessage='Olá, tudo bem? Como posso te ajudar?' 
          statusMessage='Geralmente responde em 1 hora' 
          className="whatsapp" 
      />  
    </footer>
    </Router>
    )

export default Footer;