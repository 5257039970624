import React from 'react';
import notfound from './theme/images/notfound.jpeg'
import './NotFound.css';

const NotFound = () => (
  <>
    <img className="margin img-fluid center" src={notfound} alt={notfound}/>
  </>
    )

export default NotFound;