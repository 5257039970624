import React from 'react';
// import Carousel from 'react-bootstrap/Carousel'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import aerolineas from './theme/images/carousel/aerolineas.jpeg'
import aeromexico from './theme/images/carousel/aeromexico.jpeg'
import aircanada from './theme/images/carousel/aircanada.jpeg'
import airfrance from './theme/images/carousel/airfrance.jpeg'
import alitalia from './theme/images/carousel/alitalia.jpeg'
import americanairlines from './theme/images/carousel/americanairlines.jpeg'
import avianca from './theme/images/carousel/avianca.jpeg'
import azul from './theme/images/carousel/azul.jpeg'
import british from './theme/images/carousel/british.jpeg'
import copa from './theme/images/carousel/copa.jpeg'
import delta from './theme/images/carousel/delta.jpeg'
import emirates from './theme/images/carousel/emirates.jpeg'
import gol from './theme/images/carousel/gol.jpeg'
import iberia from './theme/images/carousel/iberia.jpeg'
import klm from './theme/images/carousel/klm.jpeg'
import qatar from './theme/images/carousel/qatar.jpeg'
import swiss from './theme/images/carousel/swiss.jpeg'
import tam from './theme/images/carousel/tam.jpeg'
import tap from './theme/images/carousel/tap.jpeg'
import united from './theme/images/carousel/united.jpeg'




const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };
  

const CompanyCarousel = () => (
    <Carousel 
        responsive={responsive}
        swipeable={false}
        draggable={false}
        autoPlay={true}
        autoPlaySpeed={1800}
        infinite={true}
        removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
        centerMode={true}
  >
      
        <img src={aerolineas} alt={aerolineas} className="img-fluid"/>

        <img src={aeromexico} alt={aeromexico} className="img-fluid"/>

        <img src={aircanada} alt={aircanada} className="img-fluid"/>

        <img src={airfrance} alt={airfrance} className="img-fluid"/>
        
        <img src={alitalia} alt={alitalia} className="img-fluid"/>

        <img src={americanairlines} alt={americanairlines} className="img-fluid"/>

        <img src={avianca} alt={avianca} className="img-fluid"/>
     
        <img src={azul} alt={azul} className="img-fluid"/>

        <img src={british} alt={british} className="img-fluid"/>

        <img src={copa} alt={copa} className="img-fluid"/>

        <img src={delta} alt={delta} className="img-fluid"/>

        <img src={emirates} alt={emirates} className="img-fluid"/>

        <img src={gol} alt={gol} className="img-fluid"/>
        
        <img src={iberia} alt={iberia} className="img-fluid"/>

        <img src={klm} alt={klm} className="img-fluid"/>

        <img src={qatar} alt={qatar} className="img-fluid"/>

        <img src={swiss} alt={swiss} className="img-fluid"/>

        <img src={tam} alt={tam} className="img-fluid"/>

        <img src={tap} alt={tap} className="img-fluid"/>

        <img src={united} alt={united} className="img-fluid"/>
    </Carousel>
)

export default CompanyCarousel;
